import { setDefaultOptions } from "date-fns";

import da from "date-fns/locale/da/index";
import enGB from "date-fns/locale/en-GB/index";

const dateLocales: any = {
  da,
  en: enGB,
};

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const path = router.currentRoute.value?.path || "";
  const lang = path.match(/\/([a-z]{2})(?:\/|$)/)?.[1];

  globalThis.lang = lang || "da";
  // set date-fns locale
  setDefaultOptions({ locale: dateLocales[globalThis.lang] });
  useHead({
    htmlAttrs: {
      lang: globalThis.lang,
    },
  });
});
