<template>
  <ClientOnly>
    <div
      v-if="service"
      class="fixed inset-0 z-50 flex h-full w-full flex-col overflow-auto bg-white"
    >
      <RequestFlowComponentsFlowHeader
        v-show="requestFlowHeaderIsShown"
        :service="service"
        :current="currentSlide"
        :amount-of-slides="amountOfSlides"
        @close="emit('close')"
      />
      <div v-if="currentSlide" class="-mt-px h-full">
        <div class="flex h-full w-full items-center">
          <RequestFlowComponentsSliderWrapper
            :payload="payload"
            :questions="payload.answers"
            :service-id="serviceId"
            :vendor-id="vendorId"
            :service="service"
            @slide-changed="currentSlide = $event"
            @amount-of-slides="amountOfSlides = $event"
          />
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUIStore } from "~/store/UIStore";
import { useLeaveWarning } from "~/utils/use-leave-warning";
import { useTabPrevent } from "~/utils/use-tab-prevent";
import { track } from "~/utils/tracking/tracking";

const emit = defineEmits(["close"]);
const currentSlide = ref(1);
const amountOfSlides = ref(0);

// get vendor and service information - route takes precedence, then from passed configuration
const { requestFlowHeaderIsShown } = storeToRefs(useUIStore());
const { requestFlowConfiguration } = storeToRefs(useUIStore());
const vendorId =
  (useRoute().meta.vendorApiId as string | undefined) ||
  (requestFlowConfiguration.value.vendorId as string);
const serviceId =
  (useRoute().meta.serviceApiId as string | undefined) ||
  (requestFlowConfiguration.value.serviceId as string);

// prepare the payload
const payload = ref({
  location_id: null,
  partner_ids: vendorId ? [vendorId] : [],
  service_id: serviceId,
  answers: [],
} as OgApi.TaskPayload);

onMounted(() => {
  const { activate: activateLeaveWarning } = useLeaveWarning();
  const { activate: activateTabPrevent } = useTabPrevent();
  activateLeaveWarning();
  activateTabPrevent();
});

onUnmounted(() => {
  const { deactivate: deactivateLeaveWarning } = useLeaveWarning();
  const { deactivate: deactivateTabPrevent } = useTabPrevent();
  deactivateLeaveWarning();
  deactivateTabPrevent();
});

const { data: serviceData }: any = await useAsyncData(
  `request-flow-${serviceId}`,
  () => {
    return $fetch(
      // no need to use the auth token here, since the public endpoint serves the same
      `${useRuntimeConfig().public.apiUrl}public/services/${serviceId}`,
    );
  },
);

const service = computed<OgApi.Service | undefined>(
  () => serviceData.value?.data,
);

watch(
  service,
  () => {
    payload.value.service_id = service.value?.id || null;
    if (!service.value?.id || !service.value?.name) return;
    track({
      event: "request_flow_started",
      metadata: {
        service_name: service.value.name,
        service_id: service.value.id,
        type: "service",
        products: [
          {
            product_id: vendorId,
          },
        ],
      },
      posthogEvent: {
        name: "Request flow started",
        properties: {
          service_id: service.value.id,
          service_name: service.value.name,
        },
      },
    });
  },
  { immediate: true },
);

const questions = computed<Array<OgApi.ServiceQuestion> | undefined>(
  () => service.value?.questions,
);
// write the questions to the payload w. an empty slot for an answer
// and a few modifications to please the backend
watch(
  questions,
  () => {
    payload.value.answers =
      questions.value?.map((question) => ({
        ...question,
        answer: question.type === "composite" ? [] : null,
        question_id: question.id,
        id: undefined,
      })) || [];
  },
  { immediate: true },
);
</script>
