export function useHorizontalAlignment(props: any) {
  return computed(() => {
    const map = {
      left: "items-start",
      center: "items-center",
      right: "items-end",
    };
    const alignment = props.content.horizontal_alignment?.value;
    // backwards compatibility
    return !alignment ? map.left : map[alignment];
  });
}
