<template>
  <div class="flex w-full overflow-hidden">
    <div
      ref="slideContainer"
      class="flex h-[100vh] w-full"
      :class="{
        'ease transition-all duration-[500ms] will-change-transform':
          transitionEnabled,
      }"
    >
      <div
        v-for="(slide, index) in slides"
        :key="slide.id"
        class="mt-[56px] flex w-full shrink-0 overflow-auto px-4 md:px-0"
      >
        <component
          :is="slide.component"
          class="m-auto w-[600px] pt-4"
          v-bind="slide.props"
          :is-first="!index"
          :is-active="index + 1 === currentSlide"
          @go-to-step="goToSlide"
          @back="prevSlide"
          @next="nextSlide"
          @next-and-remove="() => {}"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { markRaw } from "vue";
import { storeToRefs } from "pinia";
import {
  RequestFlowComponentsSlidesGenericQuestionSlide,
  RequestFlowComponentsSlidesRegistrationLoginSlide,
  RequestFlowComponentsSlidesSummarySlide,
  RequestFlowComponentsSlidesVendorSelectionSlide,
} from "#components";
import { useAuthStore } from "~/store/AuthStore";
const { isLoggedIn } = storeToRefs(useAuthStore());

const props = defineProps<{
  payload: OgApi.TaskPayload;
  questions: Array<OgApi.ServiceQuestionWithAnswer>;
  serviceId?: string;
  vendorId?: string;
  service?: OgApi.Service;
}>();

const transitionEnabled = ref(true);
const currentSlide = ref(1);
const slideContainer: any | null = ref(null);
const emit = defineEmits<{
  (e: "slideChanged", current: number): void;
  (e: "amountOfSlides", current: number): void;
}>();

interface Slide {
  id: string;
  component: Component;
  props: object;
}

const slides: Ref<Slide[]> = ref([
  ...props.questions.map((question) => {
    return {
      id: question.question_id,
      component: markRaw(RequestFlowComponentsSlidesGenericQuestionSlide),
      props: {
        question,
      },
    };
  }),
  {
    id: "vendor-selection",
    component: markRaw(RequestFlowComponentsSlidesVendorSelectionSlide),
    props: {
      payload: props.payload,
      serviceId: props.serviceId,
      vendorId: props.vendorId,
    },
  },
  {
    id: "summary",
    component: markRaw(RequestFlowComponentsSlidesSummarySlide),
    props: {
      payload: props.payload,
      service: props.service,
    },
  },
]);

watchEffect(() => {
  emit("amountOfSlides", slides.value.length);
});

// make sure we have a registration / login slide when logged out and none when logged in
watchEffect(() => {
  const registrationLoginSlide = slides.value.find(
    (s) => s.id === "registration-login",
  );
  // when logged in, remove registration / login slide
  if (isLoggedIn.value && registrationLoginSlide) {
    // when a user registers/logs in, the loggedIn value changes, but we don't know yet if the
    // selected vendor is available for the user. So we wait a few seconds before removing the slide
    // because only then we determined whether the vendor slide needs to be shown
    // This is ugly, so would be better to wait here for the locations and vendors to load and then continue
    setTimeout(() => {
      removeSlide("registration-login");
    }, 2000);
  }
  // when logged out, add registration / login slide
  if (!isLoggedIn.value && !registrationLoginSlide) {
    // call add slide with the above
    addSlide(
      {
        id: "registration-login",
        component: markRaw(RequestFlowComponentsSlidesRegistrationLoginSlide),
        props: {},
      },
      slides.value.findIndex((s) => s.id === "vendor-selection"),
    );
    prevSlide();
  }
});

watch(currentSlide, (newVal) => {
  if (!slideContainer.value) return;
  slideContainer.value.style.transform = `translateX(-${(newVal - 1) * 100}%)`;
  emit("slideChanged", currentSlide.value);
});

function nextSlide() {
  currentSlide.value++;
}

// TODO: Action in here should depend on where the slide to be removed is in relation to the current slide
function removeSlide(slideId: string) {
  // this goes to the next slide
  nextSlide();
  // wait for the animation to finish, remove the slide AND shift the view back to the previous slide
  setTimeout(() => {
    disableSlideTransition();
    slides.value = slides.value.filter((slide: any) => slide.id !== slideId);
    prevSlide();
    // next tick isn't enough
    setTimeout(() => enableSlideTransition(), 50);
  }, 510);
}

// TODO: Action in here should depend on where the slide to be added is in relation to the current slide - see
// removeSlide on how to make sure there's no slide jumping or other weirdness
function addSlide(slide: Slide, beforePosition: number) {
  const slidesLocal = [...slides.value];
  slidesLocal.splice(beforePosition, 0, slide);
  slides.value = [...slidesLocal];
}

function prevSlide() {
  if (currentSlide.value === 1) return;

  currentSlide.value--;
}

function goToSlide(index: number) {
  currentSlide.value = index;
}

function enableSlideTransition() {
  transitionEnabled.value = true;
}

function disableSlideTransition() {
  transitionEnabled.value = false;
}
</script>
