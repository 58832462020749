<template>
  <NuxtLoadingIndicator
    :class="dynamicClasses"
    color="repeating-linear-gradient(to right,#9DB4FF 0%,#5B1BF0 100%)"
    :throttle="0"
  />
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";

const isEmbedded = useUIStore().isEmbedded;

const dynamicClasses = computed(() => {
  return isEmbedded
    ? "mt-[113px] md:!left-[50px] md:mt-[63px] lg-customer-app:!left-[208px]"
    : "mt-[63px]";
});
</script>
