import { default as anchor_45listB1OiM1wCAWMeta } from "/home/forge/officeguru.dk/pages/components/anchor-list.vue?macro=true";
import { default as blog_45herolyJ1XATYbBMeta } from "/home/forge/officeguru.dk/pages/components/blog/blog-hero.vue?macro=true";
import { default as blog_45previewwVcXb6WJzbMeta } from "/home/forge/officeguru.dk/pages/components/blog/blog-preview.vue?macro=true";
import { default as cart_45conflict_45modal9gSAhsMKn2Meta } from "/home/forge/officeguru.dk/pages/components/cart/cart-conflict-modal.vue?macro=true";
import { default as cart_45discount_45code7AQX4WxRD3Meta } from "/home/forge/officeguru.dk/pages/components/cart/cart-discount-code.vue?macro=true";
import { default as cart_45emptyMB3tWdnggwMeta } from "/home/forge/officeguru.dk/pages/components/cart/cart-empty.vue?macro=true";
import { default as cart_45line_45editorjHFfekpDqfMeta } from "/home/forge/officeguru.dk/pages/components/cart/cart-line-editor.vue?macro=true";
import { default as cart_45linenGlC9SwczEMeta } from "/home/forge/officeguru.dk/pages/components/cart/cart-line.vue?macro=true";
import { default as cart_45order_45previewDTiQgJkxbHMeta } from "/home/forge/officeguru.dk/pages/components/cart/cart-order-preview.vue?macro=true";
import { default as cart_45productsUT3dIIEbd6Meta } from "/home/forge/officeguru.dk/pages/components/cart/cart-products.vue?macro=true";
import { default as checkout_45wrapperRkyGSv1iI7Meta } from "/home/forge/officeguru.dk/pages/components/checkout/checkout-wrapper.vue?macro=true";
import { default as expandable_45descriptionba0zqpKtE6Meta } from "/home/forge/officeguru.dk/pages/components/expandable-description.vue?macro=true";
import { default as faq_45listpKA5VACB6sMeta } from "/home/forge/officeguru.dk/pages/components/faq-list.vue?macro=true";
import { default as get_45offer_45boxuOPqkijVDnMeta } from "/home/forge/officeguru.dk/pages/components/get-offer-box.vue?macro=true";
import { default as mobile_45wrapped_45contact_45formnjFkcRGePOMeta } from "/home/forge/officeguru.dk/pages/components/mobile-wrapped-contact-form.vue?macro=true";
import { default as single_45productIEOderJcboMeta } from "/home/forge/officeguru.dk/pages/components/single-product.vue?macro=true";
import { default as single_45search_45filter_45bartQyCjUlMNJMeta } from "/home/forge/officeguru.dk/pages/components/single-search-filter-bar.vue?macro=true";
import { default as single_45search_45filter_45typegz2N6XKgXVMeta } from "/home/forge/officeguru.dk/pages/components/single-search-filter-type.vue?macro=true";
import { default as single_45search_45filter_45vendor_45namettX7bonH4aMeta } from "/home/forge/officeguru.dk/pages/components/single-search-filter-vendor-name.vue?macro=true";
import { default as single_45search_45filtersWYVscYz0J8Meta } from "/home/forge/officeguru.dk/pages/components/single-search-filters.vue?macro=true";
import { default as single_45search_45mobile_45filtersxqcNOXmPyiMeta } from "/home/forge/officeguru.dk/pages/components/single-search-mobile-filters.vue?macro=true";
import { default as single_45search_45productsvVfFbtYrlqMeta } from "/home/forge/officeguru.dk/pages/components/single-search-products.vue?macro=true";
import { default as single_45search_45servicesNqlVGFXiJLMeta } from "/home/forge/officeguru.dk/pages/components/single-search-services.vue?macro=true";
import { default as single_45search_45vendorsvJEvD8Z6m8Meta } from "/home/forge/officeguru.dk/pages/components/single-search-vendors.vue?macro=true";
import { default as single_45service_45hero9I5rytmNdBMeta } from "/home/forge/officeguru.dk/pages/components/single-service-hero.vue?macro=true";
import { default as single_45service_45others_45boughtxepPW75KY6Meta } from "/home/forge/officeguru.dk/pages/components/single-service-others-bought.vue?macro=true";
import { default as single_45service_45reviewsaQU56Zy7gnMeta } from "/home/forge/officeguru.dk/pages/components/single-service-reviews.vue?macro=true";
import { default as single_45service_45vendorsmNUtPqQkeSMeta } from "/home/forge/officeguru.dk/pages/components/single-service-vendors.vue?macro=true";
import { default as single_45service_45why_45officeguruofeR30wXJ8Meta } from "/home/forge/officeguru.dk/pages/components/single-service-why-officeguru.vue?macro=true";
import { default as single_45service_45vendors_45service_45ratinggBYHdyCTk3Meta } from "/home/forge/officeguru.dk/pages/components/vendor/single-service-vendors-service-rating.vue?macro=true";
import { default as single_45service_45vendors_45serviceneG6KgiUMVMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-service-vendors-service.vue?macro=true";
import { default as single_45vendor_45category_45listwdikFY98SOMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-category-list.vue?macro=true";
import { default as single_45vendor_45headerAqBcx9K9SVMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-header.vue?macro=true";
import { default as single_45vendor_45images_45tile05fnrtsB53Meta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-images-tile.vue?macro=true";
import { default as single_45vendor_45imageshOT17rtvWUMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-images.vue?macro=true";
import { default as single_45vendor_45operational_45areasfPUA9Szl2NMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-operational-areas.vue?macro=true";
import { default as single_45vendor_45overview2IJYUGJaWAMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-overview.vue?macro=true";
import { default as single_45vendor_45products_45tableHOToD9pQH0Meta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-products-table.vue?macro=true";
import { default as single_45vendor_45productsZ1pXgwjkyTMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-products.vue?macro=true";
import { default as single_45vendor_45ratings0FtaUGZrXDMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-ratings.vue?macro=true";
import { default as single_45vendor_45reviews_45filter_45barhp92IrPtZuMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews-filter-bar.vue?macro=true";
import { default as single_45vendor_45reviews_45listSMnkhkv00CMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews-list.vue?macro=true";
import { default as single_45vendor_45reviews_45rating_45filterdwwydOcDccMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews-rating-filter.vue?macro=true";
import { default as single_45vendor_45reviews3vr9V7PCi5Meta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews.vue?macro=true";
import { default as single_45vendor_45service_45meta_45rendererIt6tBEwY8lMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-service-meta-renderer.vue?macro=true";
import { default as single_45vendor_45servicesPiclnWUar9Meta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-services.vue?macro=true";
import { default as single_45vendor_45subpage_45headerHea35IJfOlMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-subpage-header.vue?macro=true";
import { default as single_45vendor_45verification_45and_45payment63RqmGzQrVMeta } from "/home/forge/officeguru.dk/pages/components/vendor/single-vendor-verification-and-payment.vue?macro=true";
import { default as wrapped_45contact_45formigjFY3muTpMeta } from "/home/forge/officeguru.dk/pages/components/wrapped-contact-form.vue?macro=true";
import { default as showroomMqcmaj9yptMeta } from "/home/forge/officeguru.dk/pages/showroom.vue?macro=true";
import { default as single_45blogE9YG6Xgt6HMeta } from "/home/forge/officeguru.dk/pages/single-blog.vue?macro=true";
import { default as single_45cartVuXl2Dn6yWMeta } from "/home/forge/officeguru.dk/pages/single-cart.vue?macro=true";
import { default as single_45checkout4Tgh1Zr8gJMeta } from "/home/forge/officeguru.dk/pages/single-checkout.vue?macro=true";
import { default as single_45searchUO4TRbu95SMeta } from "/home/forge/officeguru.dk/pages/single-search.vue?macro=true";
import { default as single_45servicezYocpEdSkCMeta } from "/home/forge/officeguru.dk/pages/single-service.vue?macro=true";
import { default as single_45vendor_45products_45listyNt0Q2N6kAMeta } from "/home/forge/officeguru.dk/pages/single-vendor-products-list.vue?macro=true";
import { default as single_45vendorbjBZ3CuNMbMeta } from "/home/forge/officeguru.dk/pages/single-vendor.vue?macro=true";
export default [
  {
    name: anchor_45listB1OiM1wCAWMeta?.name ?? "components-anchor-list",
    path: anchor_45listB1OiM1wCAWMeta?.path ?? "/components/anchor-list",
    meta: anchor_45listB1OiM1wCAWMeta || {},
    alias: anchor_45listB1OiM1wCAWMeta?.alias || [],
    redirect: anchor_45listB1OiM1wCAWMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/anchor-list.vue").then(m => m.default || m)
  },
  {
    name: blog_45herolyJ1XATYbBMeta?.name ?? "components-blog-blog-hero",
    path: blog_45herolyJ1XATYbBMeta?.path ?? "/components/blog/blog-hero",
    meta: blog_45herolyJ1XATYbBMeta || {},
    alias: blog_45herolyJ1XATYbBMeta?.alias || [],
    redirect: blog_45herolyJ1XATYbBMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/blog/blog-hero.vue").then(m => m.default || m)
  },
  {
    name: blog_45previewwVcXb6WJzbMeta?.name ?? "components-blog-blog-preview",
    path: blog_45previewwVcXb6WJzbMeta?.path ?? "/components/blog/blog-preview",
    meta: blog_45previewwVcXb6WJzbMeta || {},
    alias: blog_45previewwVcXb6WJzbMeta?.alias || [],
    redirect: blog_45previewwVcXb6WJzbMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/blog/blog-preview.vue").then(m => m.default || m)
  },
  {
    name: cart_45conflict_45modal9gSAhsMKn2Meta?.name ?? "components-cart-cart-conflict-modal",
    path: cart_45conflict_45modal9gSAhsMKn2Meta?.path ?? "/components/cart/cart-conflict-modal",
    meta: cart_45conflict_45modal9gSAhsMKn2Meta || {},
    alias: cart_45conflict_45modal9gSAhsMKn2Meta?.alias || [],
    redirect: cart_45conflict_45modal9gSAhsMKn2Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/cart/cart-conflict-modal.vue").then(m => m.default || m)
  },
  {
    name: cart_45discount_45code7AQX4WxRD3Meta?.name ?? "components-cart-cart-discount-code",
    path: cart_45discount_45code7AQX4WxRD3Meta?.path ?? "/components/cart/cart-discount-code",
    meta: cart_45discount_45code7AQX4WxRD3Meta || {},
    alias: cart_45discount_45code7AQX4WxRD3Meta?.alias || [],
    redirect: cart_45discount_45code7AQX4WxRD3Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/cart/cart-discount-code.vue").then(m => m.default || m)
  },
  {
    name: cart_45emptyMB3tWdnggwMeta?.name ?? "components-cart-cart-empty",
    path: cart_45emptyMB3tWdnggwMeta?.path ?? "/components/cart/cart-empty",
    meta: cart_45emptyMB3tWdnggwMeta || {},
    alias: cart_45emptyMB3tWdnggwMeta?.alias || [],
    redirect: cart_45emptyMB3tWdnggwMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/cart/cart-empty.vue").then(m => m.default || m)
  },
  {
    name: cart_45line_45editorjHFfekpDqfMeta?.name ?? "components-cart-cart-line-editor",
    path: cart_45line_45editorjHFfekpDqfMeta?.path ?? "/components/cart/cart-line-editor",
    meta: cart_45line_45editorjHFfekpDqfMeta || {},
    alias: cart_45line_45editorjHFfekpDqfMeta?.alias || [],
    redirect: cart_45line_45editorjHFfekpDqfMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/cart/cart-line-editor.vue").then(m => m.default || m)
  },
  {
    name: cart_45linenGlC9SwczEMeta?.name ?? "components-cart-cart-line",
    path: cart_45linenGlC9SwczEMeta?.path ?? "/components/cart/cart-line",
    meta: cart_45linenGlC9SwczEMeta || {},
    alias: cart_45linenGlC9SwczEMeta?.alias || [],
    redirect: cart_45linenGlC9SwczEMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/cart/cart-line.vue").then(m => m.default || m)
  },
  {
    name: cart_45order_45previewDTiQgJkxbHMeta?.name ?? "components-cart-cart-order-preview",
    path: cart_45order_45previewDTiQgJkxbHMeta?.path ?? "/components/cart/cart-order-preview",
    meta: cart_45order_45previewDTiQgJkxbHMeta || {},
    alias: cart_45order_45previewDTiQgJkxbHMeta?.alias || [],
    redirect: cart_45order_45previewDTiQgJkxbHMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/cart/cart-order-preview.vue").then(m => m.default || m)
  },
  {
    name: cart_45productsUT3dIIEbd6Meta?.name ?? "components-cart-cart-products",
    path: cart_45productsUT3dIIEbd6Meta?.path ?? "/components/cart/cart-products",
    meta: cart_45productsUT3dIIEbd6Meta || {},
    alias: cart_45productsUT3dIIEbd6Meta?.alias || [],
    redirect: cart_45productsUT3dIIEbd6Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/cart/cart-products.vue").then(m => m.default || m)
  },
  {
    name: checkout_45wrapperRkyGSv1iI7Meta?.name ?? "components-checkout-checkout-wrapper",
    path: checkout_45wrapperRkyGSv1iI7Meta?.path ?? "/components/checkout/checkout-wrapper",
    meta: checkout_45wrapperRkyGSv1iI7Meta || {},
    alias: checkout_45wrapperRkyGSv1iI7Meta?.alias || [],
    redirect: checkout_45wrapperRkyGSv1iI7Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/checkout/checkout-wrapper.vue").then(m => m.default || m)
  },
  {
    name: expandable_45descriptionba0zqpKtE6Meta?.name ?? "components-expandable-description",
    path: expandable_45descriptionba0zqpKtE6Meta?.path ?? "/components/expandable-description",
    meta: expandable_45descriptionba0zqpKtE6Meta || {},
    alias: expandable_45descriptionba0zqpKtE6Meta?.alias || [],
    redirect: expandable_45descriptionba0zqpKtE6Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/expandable-description.vue").then(m => m.default || m)
  },
  {
    name: faq_45listpKA5VACB6sMeta?.name ?? "components-faq-list",
    path: faq_45listpKA5VACB6sMeta?.path ?? "/components/faq-list",
    meta: faq_45listpKA5VACB6sMeta || {},
    alias: faq_45listpKA5VACB6sMeta?.alias || [],
    redirect: faq_45listpKA5VACB6sMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/faq-list.vue").then(m => m.default || m)
  },
  {
    name: get_45offer_45boxuOPqkijVDnMeta?.name ?? "components-get-offer-box",
    path: get_45offer_45boxuOPqkijVDnMeta?.path ?? "/components/get-offer-box",
    meta: get_45offer_45boxuOPqkijVDnMeta || {},
    alias: get_45offer_45boxuOPqkijVDnMeta?.alias || [],
    redirect: get_45offer_45boxuOPqkijVDnMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/get-offer-box.vue").then(m => m.default || m)
  },
  {
    name: mobile_45wrapped_45contact_45formnjFkcRGePOMeta?.name ?? "components-mobile-wrapped-contact-form",
    path: mobile_45wrapped_45contact_45formnjFkcRGePOMeta?.path ?? "/components/mobile-wrapped-contact-form",
    meta: mobile_45wrapped_45contact_45formnjFkcRGePOMeta || {},
    alias: mobile_45wrapped_45contact_45formnjFkcRGePOMeta?.alias || [],
    redirect: mobile_45wrapped_45contact_45formnjFkcRGePOMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/mobile-wrapped-contact-form.vue").then(m => m.default || m)
  },
  {
    name: single_45productIEOderJcboMeta?.name ?? "components-single-product",
    path: single_45productIEOderJcboMeta?.path ?? "/components/single-product",
    meta: single_45productIEOderJcboMeta || {},
    alias: single_45productIEOderJcboMeta?.alias || [],
    redirect: single_45productIEOderJcboMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-product.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45filter_45bartQyCjUlMNJMeta?.name ?? "components-single-search-filter-bar",
    path: single_45search_45filter_45bartQyCjUlMNJMeta?.path ?? "/components/single-search-filter-bar",
    meta: single_45search_45filter_45bartQyCjUlMNJMeta || {},
    alias: single_45search_45filter_45bartQyCjUlMNJMeta?.alias || [],
    redirect: single_45search_45filter_45bartQyCjUlMNJMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-filter-bar.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45filter_45typegz2N6XKgXVMeta?.name ?? "components-single-search-filter-type",
    path: single_45search_45filter_45typegz2N6XKgXVMeta?.path ?? "/components/single-search-filter-type",
    meta: single_45search_45filter_45typegz2N6XKgXVMeta || {},
    alias: single_45search_45filter_45typegz2N6XKgXVMeta?.alias || [],
    redirect: single_45search_45filter_45typegz2N6XKgXVMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-filter-type.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45filter_45vendor_45namettX7bonH4aMeta?.name ?? "components-single-search-filter-vendor-name",
    path: single_45search_45filter_45vendor_45namettX7bonH4aMeta?.path ?? "/components/single-search-filter-vendor-name",
    meta: single_45search_45filter_45vendor_45namettX7bonH4aMeta || {},
    alias: single_45search_45filter_45vendor_45namettX7bonH4aMeta?.alias || [],
    redirect: single_45search_45filter_45vendor_45namettX7bonH4aMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-filter-vendor-name.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45filtersWYVscYz0J8Meta?.name ?? "components-single-search-filters",
    path: single_45search_45filtersWYVscYz0J8Meta?.path ?? "/components/single-search-filters",
    meta: single_45search_45filtersWYVscYz0J8Meta || {},
    alias: single_45search_45filtersWYVscYz0J8Meta?.alias || [],
    redirect: single_45search_45filtersWYVscYz0J8Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-filters.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45mobile_45filtersxqcNOXmPyiMeta?.name ?? "components-single-search-mobile-filters",
    path: single_45search_45mobile_45filtersxqcNOXmPyiMeta?.path ?? "/components/single-search-mobile-filters",
    meta: single_45search_45mobile_45filtersxqcNOXmPyiMeta || {},
    alias: single_45search_45mobile_45filtersxqcNOXmPyiMeta?.alias || [],
    redirect: single_45search_45mobile_45filtersxqcNOXmPyiMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-mobile-filters.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45productsvVfFbtYrlqMeta?.name ?? "components-single-search-products",
    path: single_45search_45productsvVfFbtYrlqMeta?.path ?? "/components/single-search-products",
    meta: single_45search_45productsvVfFbtYrlqMeta || {},
    alias: single_45search_45productsvVfFbtYrlqMeta?.alias || [],
    redirect: single_45search_45productsvVfFbtYrlqMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-products.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45servicesNqlVGFXiJLMeta?.name ?? "components-single-search-services",
    path: single_45search_45servicesNqlVGFXiJLMeta?.path ?? "/components/single-search-services",
    meta: single_45search_45servicesNqlVGFXiJLMeta || {},
    alias: single_45search_45servicesNqlVGFXiJLMeta?.alias || [],
    redirect: single_45search_45servicesNqlVGFXiJLMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-services.vue").then(m => m.default || m)
  },
  {
    name: single_45search_45vendorsvJEvD8Z6m8Meta?.name ?? "components-single-search-vendors",
    path: single_45search_45vendorsvJEvD8Z6m8Meta?.path ?? "/components/single-search-vendors",
    meta: single_45search_45vendorsvJEvD8Z6m8Meta || {},
    alias: single_45search_45vendorsvJEvD8Z6m8Meta?.alias || [],
    redirect: single_45search_45vendorsvJEvD8Z6m8Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-search-vendors.vue").then(m => m.default || m)
  },
  {
    name: single_45service_45hero9I5rytmNdBMeta?.name ?? "components-single-service-hero",
    path: single_45service_45hero9I5rytmNdBMeta?.path ?? "/components/single-service-hero",
    meta: single_45service_45hero9I5rytmNdBMeta || {},
    alias: single_45service_45hero9I5rytmNdBMeta?.alias || [],
    redirect: single_45service_45hero9I5rytmNdBMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-service-hero.vue").then(m => m.default || m)
  },
  {
    name: single_45service_45others_45boughtxepPW75KY6Meta?.name ?? "components-single-service-others-bought",
    path: single_45service_45others_45boughtxepPW75KY6Meta?.path ?? "/components/single-service-others-bought",
    meta: single_45service_45others_45boughtxepPW75KY6Meta || {},
    alias: single_45service_45others_45boughtxepPW75KY6Meta?.alias || [],
    redirect: single_45service_45others_45boughtxepPW75KY6Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-service-others-bought.vue").then(m => m.default || m)
  },
  {
    name: single_45service_45reviewsaQU56Zy7gnMeta?.name ?? "components-single-service-reviews",
    path: single_45service_45reviewsaQU56Zy7gnMeta?.path ?? "/components/single-service-reviews",
    meta: single_45service_45reviewsaQU56Zy7gnMeta || {},
    alias: single_45service_45reviewsaQU56Zy7gnMeta?.alias || [],
    redirect: single_45service_45reviewsaQU56Zy7gnMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-service-reviews.vue").then(m => m.default || m)
  },
  {
    name: single_45service_45vendorsmNUtPqQkeSMeta?.name ?? "components-single-service-vendors",
    path: single_45service_45vendorsmNUtPqQkeSMeta?.path ?? "/components/single-service-vendors",
    meta: single_45service_45vendorsmNUtPqQkeSMeta || {},
    alias: single_45service_45vendorsmNUtPqQkeSMeta?.alias || [],
    redirect: single_45service_45vendorsmNUtPqQkeSMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-service-vendors.vue").then(m => m.default || m)
  },
  {
    name: single_45service_45why_45officeguruofeR30wXJ8Meta?.name ?? "components-single-service-why-officeguru",
    path: single_45service_45why_45officeguruofeR30wXJ8Meta?.path ?? "/components/single-service-why-officeguru",
    meta: single_45service_45why_45officeguruofeR30wXJ8Meta || {},
    alias: single_45service_45why_45officeguruofeR30wXJ8Meta?.alias || [],
    redirect: single_45service_45why_45officeguruofeR30wXJ8Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/single-service-why-officeguru.vue").then(m => m.default || m)
  },
  {
    name: single_45service_45vendors_45service_45ratinggBYHdyCTk3Meta?.name ?? "components-vendor-single-service-vendors-service-rating",
    path: single_45service_45vendors_45service_45ratinggBYHdyCTk3Meta?.path ?? "/components/vendor/single-service-vendors-service-rating",
    meta: single_45service_45vendors_45service_45ratinggBYHdyCTk3Meta || {},
    alias: single_45service_45vendors_45service_45ratinggBYHdyCTk3Meta?.alias || [],
    redirect: single_45service_45vendors_45service_45ratinggBYHdyCTk3Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-service-vendors-service-rating.vue").then(m => m.default || m)
  },
  {
    name: single_45service_45vendors_45serviceneG6KgiUMVMeta?.name ?? "components-vendor-single-service-vendors-service",
    path: single_45service_45vendors_45serviceneG6KgiUMVMeta?.path ?? "/components/vendor/single-service-vendors-service",
    meta: single_45service_45vendors_45serviceneG6KgiUMVMeta || {},
    alias: single_45service_45vendors_45serviceneG6KgiUMVMeta?.alias || [],
    redirect: single_45service_45vendors_45serviceneG6KgiUMVMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-service-vendors-service.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45category_45listwdikFY98SOMeta?.name ?? "components-vendor-single-vendor-category-list",
    path: single_45vendor_45category_45listwdikFY98SOMeta?.path ?? "/components/vendor/single-vendor-category-list",
    meta: single_45vendor_45category_45listwdikFY98SOMeta || {},
    alias: single_45vendor_45category_45listwdikFY98SOMeta?.alias || [],
    redirect: single_45vendor_45category_45listwdikFY98SOMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-category-list.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45headerAqBcx9K9SVMeta?.name ?? "components-vendor-single-vendor-header",
    path: single_45vendor_45headerAqBcx9K9SVMeta?.path ?? "/components/vendor/single-vendor-header",
    meta: single_45vendor_45headerAqBcx9K9SVMeta || {},
    alias: single_45vendor_45headerAqBcx9K9SVMeta?.alias || [],
    redirect: single_45vendor_45headerAqBcx9K9SVMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-header.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45images_45tile05fnrtsB53Meta?.name ?? "components-vendor-single-vendor-images-tile",
    path: single_45vendor_45images_45tile05fnrtsB53Meta?.path ?? "/components/vendor/single-vendor-images-tile",
    meta: single_45vendor_45images_45tile05fnrtsB53Meta || {},
    alias: single_45vendor_45images_45tile05fnrtsB53Meta?.alias || [],
    redirect: single_45vendor_45images_45tile05fnrtsB53Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-images-tile.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45imageshOT17rtvWUMeta?.name ?? "components-vendor-single-vendor-images",
    path: single_45vendor_45imageshOT17rtvWUMeta?.path ?? "/components/vendor/single-vendor-images",
    meta: single_45vendor_45imageshOT17rtvWUMeta || {},
    alias: single_45vendor_45imageshOT17rtvWUMeta?.alias || [],
    redirect: single_45vendor_45imageshOT17rtvWUMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-images.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45operational_45areasfPUA9Szl2NMeta?.name ?? "components-vendor-single-vendor-operational-areas",
    path: single_45vendor_45operational_45areasfPUA9Szl2NMeta?.path ?? "/components/vendor/single-vendor-operational-areas",
    meta: single_45vendor_45operational_45areasfPUA9Szl2NMeta || {},
    alias: single_45vendor_45operational_45areasfPUA9Szl2NMeta?.alias || [],
    redirect: single_45vendor_45operational_45areasfPUA9Szl2NMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-operational-areas.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45overview2IJYUGJaWAMeta?.name ?? "components-vendor-single-vendor-overview",
    path: single_45vendor_45overview2IJYUGJaWAMeta?.path ?? "/components/vendor/single-vendor-overview",
    meta: single_45vendor_45overview2IJYUGJaWAMeta || {},
    alias: single_45vendor_45overview2IJYUGJaWAMeta?.alias || [],
    redirect: single_45vendor_45overview2IJYUGJaWAMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-overview.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45products_45tableHOToD9pQH0Meta?.name ?? "components-vendor-single-vendor-products-table",
    path: single_45vendor_45products_45tableHOToD9pQH0Meta?.path ?? "/components/vendor/single-vendor-products-table",
    meta: single_45vendor_45products_45tableHOToD9pQH0Meta || {},
    alias: single_45vendor_45products_45tableHOToD9pQH0Meta?.alias || [],
    redirect: single_45vendor_45products_45tableHOToD9pQH0Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-products-table.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45productsZ1pXgwjkyTMeta?.name ?? "components-vendor-single-vendor-products",
    path: single_45vendor_45productsZ1pXgwjkyTMeta?.path ?? "/components/vendor/single-vendor-products",
    meta: single_45vendor_45productsZ1pXgwjkyTMeta || {},
    alias: single_45vendor_45productsZ1pXgwjkyTMeta?.alias || [],
    redirect: single_45vendor_45productsZ1pXgwjkyTMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-products.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45ratings0FtaUGZrXDMeta?.name ?? "components-vendor-single-vendor-ratings",
    path: single_45vendor_45ratings0FtaUGZrXDMeta?.path ?? "/components/vendor/single-vendor-ratings",
    meta: single_45vendor_45ratings0FtaUGZrXDMeta || {},
    alias: single_45vendor_45ratings0FtaUGZrXDMeta?.alias || [],
    redirect: single_45vendor_45ratings0FtaUGZrXDMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-ratings.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45reviews_45filter_45barhp92IrPtZuMeta?.name ?? "components-vendor-single-vendor-reviews-filter-bar",
    path: single_45vendor_45reviews_45filter_45barhp92IrPtZuMeta?.path ?? "/components/vendor/single-vendor-reviews-filter-bar",
    meta: single_45vendor_45reviews_45filter_45barhp92IrPtZuMeta || {},
    alias: single_45vendor_45reviews_45filter_45barhp92IrPtZuMeta?.alias || [],
    redirect: single_45vendor_45reviews_45filter_45barhp92IrPtZuMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews-filter-bar.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45reviews_45listSMnkhkv00CMeta?.name ?? "components-vendor-single-vendor-reviews-list",
    path: single_45vendor_45reviews_45listSMnkhkv00CMeta?.path ?? "/components/vendor/single-vendor-reviews-list",
    meta: single_45vendor_45reviews_45listSMnkhkv00CMeta || {},
    alias: single_45vendor_45reviews_45listSMnkhkv00CMeta?.alias || [],
    redirect: single_45vendor_45reviews_45listSMnkhkv00CMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews-list.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45reviews_45rating_45filterdwwydOcDccMeta?.name ?? "components-vendor-single-vendor-reviews-rating-filter",
    path: single_45vendor_45reviews_45rating_45filterdwwydOcDccMeta?.path ?? "/components/vendor/single-vendor-reviews-rating-filter",
    meta: single_45vendor_45reviews_45rating_45filterdwwydOcDccMeta || {},
    alias: single_45vendor_45reviews_45rating_45filterdwwydOcDccMeta?.alias || [],
    redirect: single_45vendor_45reviews_45rating_45filterdwwydOcDccMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews-rating-filter.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45reviews3vr9V7PCi5Meta?.name ?? "components-vendor-single-vendor-reviews",
    path: single_45vendor_45reviews3vr9V7PCi5Meta?.path ?? "/components/vendor/single-vendor-reviews",
    meta: single_45vendor_45reviews3vr9V7PCi5Meta || {},
    alias: single_45vendor_45reviews3vr9V7PCi5Meta?.alias || [],
    redirect: single_45vendor_45reviews3vr9V7PCi5Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-reviews.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45service_45meta_45rendererIt6tBEwY8lMeta?.name ?? "components-vendor-single-vendor-service-meta-renderer",
    path: single_45vendor_45service_45meta_45rendererIt6tBEwY8lMeta?.path ?? "/components/vendor/single-vendor-service-meta-renderer",
    meta: single_45vendor_45service_45meta_45rendererIt6tBEwY8lMeta || {},
    alias: single_45vendor_45service_45meta_45rendererIt6tBEwY8lMeta?.alias || [],
    redirect: single_45vendor_45service_45meta_45rendererIt6tBEwY8lMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-service-meta-renderer.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45servicesPiclnWUar9Meta?.name ?? "components-vendor-single-vendor-services",
    path: single_45vendor_45servicesPiclnWUar9Meta?.path ?? "/components/vendor/single-vendor-services",
    meta: single_45vendor_45servicesPiclnWUar9Meta || {},
    alias: single_45vendor_45servicesPiclnWUar9Meta?.alias || [],
    redirect: single_45vendor_45servicesPiclnWUar9Meta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-services.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45subpage_45headerHea35IJfOlMeta?.name ?? "components-vendor-single-vendor-subpage-header",
    path: single_45vendor_45subpage_45headerHea35IJfOlMeta?.path ?? "/components/vendor/single-vendor-subpage-header",
    meta: single_45vendor_45subpage_45headerHea35IJfOlMeta || {},
    alias: single_45vendor_45subpage_45headerHea35IJfOlMeta?.alias || [],
    redirect: single_45vendor_45subpage_45headerHea35IJfOlMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-subpage-header.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45verification_45and_45payment63RqmGzQrVMeta?.name ?? "components-vendor-single-vendor-verification-and-payment",
    path: single_45vendor_45verification_45and_45payment63RqmGzQrVMeta?.path ?? "/components/vendor/single-vendor-verification-and-payment",
    meta: single_45vendor_45verification_45and_45payment63RqmGzQrVMeta || {},
    alias: single_45vendor_45verification_45and_45payment63RqmGzQrVMeta?.alias || [],
    redirect: single_45vendor_45verification_45and_45payment63RqmGzQrVMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/vendor/single-vendor-verification-and-payment.vue").then(m => m.default || m)
  },
  {
    name: wrapped_45contact_45formigjFY3muTpMeta?.name ?? "components-wrapped-contact-form",
    path: wrapped_45contact_45formigjFY3muTpMeta?.path ?? "/components/wrapped-contact-form",
    meta: wrapped_45contact_45formigjFY3muTpMeta || {},
    alias: wrapped_45contact_45formigjFY3muTpMeta?.alias || [],
    redirect: wrapped_45contact_45formigjFY3muTpMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/components/wrapped-contact-form.vue").then(m => m.default || m)
  },
  {
    name: showroomMqcmaj9yptMeta?.name ?? "showroom",
    path: showroomMqcmaj9yptMeta?.path ?? "/showroom",
    meta: showroomMqcmaj9yptMeta || {},
    alias: showroomMqcmaj9yptMeta?.alias || [],
    redirect: showroomMqcmaj9yptMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: single_45blogE9YG6Xgt6HMeta?.name ?? "single-blog",
    path: single_45blogE9YG6Xgt6HMeta?.path ?? "/single-blog",
    meta: single_45blogE9YG6Xgt6HMeta || {},
    alias: single_45blogE9YG6Xgt6HMeta?.alias || [],
    redirect: single_45blogE9YG6Xgt6HMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/single-blog.vue").then(m => m.default || m)
  },
  {
    name: single_45cartVuXl2Dn6yWMeta?.name ?? "single-cart",
    path: single_45cartVuXl2Dn6yWMeta?.path ?? "/single-cart",
    meta: single_45cartVuXl2Dn6yWMeta || {},
    alias: single_45cartVuXl2Dn6yWMeta?.alias || [],
    redirect: single_45cartVuXl2Dn6yWMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/single-cart.vue").then(m => m.default || m)
  },
  {
    name: single_45checkout4Tgh1Zr8gJMeta?.name ?? "single-checkout",
    path: single_45checkout4Tgh1Zr8gJMeta?.path ?? "/single-checkout",
    meta: single_45checkout4Tgh1Zr8gJMeta || {},
    alias: single_45checkout4Tgh1Zr8gJMeta?.alias || [],
    redirect: single_45checkout4Tgh1Zr8gJMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/single-checkout.vue").then(m => m.default || m)
  },
  {
    name: single_45searchUO4TRbu95SMeta?.name ?? "single-search",
    path: single_45searchUO4TRbu95SMeta?.path ?? "/single-search",
    meta: single_45searchUO4TRbu95SMeta || {},
    alias: single_45searchUO4TRbu95SMeta?.alias || [],
    redirect: single_45searchUO4TRbu95SMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/single-search.vue").then(m => m.default || m)
  },
  {
    name: single_45servicezYocpEdSkCMeta?.name ?? "single-service",
    path: single_45servicezYocpEdSkCMeta?.path ?? "/single-service",
    meta: single_45servicezYocpEdSkCMeta || {},
    alias: single_45servicezYocpEdSkCMeta?.alias || [],
    redirect: single_45servicezYocpEdSkCMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/single-service.vue").then(m => m.default || m)
  },
  {
    name: single_45vendor_45products_45listyNt0Q2N6kAMeta?.name ?? "single-vendor-products-list",
    path: single_45vendor_45products_45listyNt0Q2N6kAMeta?.path ?? "/single-vendor-products-list",
    meta: single_45vendor_45products_45listyNt0Q2N6kAMeta || {},
    alias: single_45vendor_45products_45listyNt0Q2N6kAMeta?.alias || [],
    redirect: single_45vendor_45products_45listyNt0Q2N6kAMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/single-vendor-products-list.vue").then(m => m.default || m)
  },
  {
    name: single_45vendorbjBZ3CuNMbMeta?.name ?? "single-vendor",
    path: single_45vendorbjBZ3CuNMbMeta?.path ?? "/single-vendor",
    meta: single_45vendorbjBZ3CuNMbMeta || {},
    alias: single_45vendorbjBZ3CuNMbMeta?.alias || [],
    redirect: single_45vendorbjBZ3CuNMbMeta?.redirect || undefined,
    component: () => import("/home/forge/officeguru.dk/pages/single-vendor.vue").then(m => m.default || m)
  }
]